import React, { useEffect, useState } from "react";
import { Box, keyframes, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { COLOR, FONT_SIZE } from "../../enums/styles";
import { useNavigate } from "react-router-dom";
import { UnifiedButton } from "../UnifiedButton-component/UnifiedButton";
import { StarRating } from "./Feedback-StarRating";
import axios from "axios";
import { EMAILER_URL, RECIPIENT_URL } from "../../helpers/constants";
import { useSelector } from "react-redux";
import { selectCallDuration } from "../../redux/callSlice";

export const Feedback = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [stars, setStars] = useState(0);
  const [isSendFeedback, setIsSendFeedback] = useState(false);
  const [textFeedback, setTextFeedback] = useState("");
  const callDuration = useSelector(selectCallDuration);

  const formatDuration = (milliseconds) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
      2,
      "0"
    )}`;
  };

  const handleSendFeedback = () => {
    setIsSendFeedback(true);

    const callDate = new Date();

    const subject = "Call Raiting Update";
    const text = `
    Message: "${textFeedback}"
    Rating: "${stars}" \n
    Call Duration: "${formatDuration(callDuration)}" \n
    Call Date: "${callDate}"
    `;
    const recipients = RECIPIENT_URL;

    const emailData = {
      to: recipients,
      subject,
      text,
    };

    axios
      .post(EMAILER_URL, emailData)
      .then((response) => {
        console.log("Email sent successfully");
      })
      .catch((error) => {
        console.error("Error sending email:", error);
      });
  };

  if (isSendFeedback) {
    window.sessionStorage.removeItem("userStage")
    window.sessionStorage.removeItem("callNumber")
    window.sessionStorage.removeItem("reloadState")
    window.location.reload()
    navigate("/")
  }

  const expand = keyframes`
  from {
    max-height: 0;
  }
  to {
    max-height: 500px; /* Adjust maxHeight value according to your needs */
  }
`;

  const collapse = keyframes`
  from {
    max-height: 500px; /* Adjust maxHeight value according to your needs */
  }
  to {
    max-height: 0;
  }
`;

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      gap={3}
    >
      <Typography
        fontSize={FONT_SIZE.HEADERS}
        fontWeight="700"
        textAlign="center"
      >
        {t("how_was_your_call")}
      </Typography>
      <Box>
        <StarRating name="rating" value={stars} onChange={setStars} />
        <Box
          sx={{
            animation:
              stars <= 3 && stars > 0
                ? `${expand} 0.8s ease forwards`
                : `${collapse} 0.8s ease forwards`,
            opacity: stars <= 3 && stars > 0 ? 1 : 0,
            overflow: "hidden",
            width: "100%",
          }}
        >
          <TextField
            id="filled-multiline-static"
            label={t("experience")}
            InputLabelProps={{
              sx: {
                fontSize: "20px",
                color: COLOR.PURPLE,
                "&.Mui-focused": {
                  color: COLOR.PURPLE,
                  fontSize: "16px",
                },
              },
            }}
            sx={{
              backgroundColor: "#E8E8E8",
              mt: "10px",
              "& .MuiOutlinedInput-root": {
                "& fieldset": { borderColor: COLOR.PURPLE },
                "&:hover fieldset": { borderColor: COLOR.PURPLE },
                "&.Mui-focused fieldset": { borderColor: COLOR.PURPLE },
              },
            }}
            multiline
            fullWidth
            onChange={(e) => setTextFeedback(e.target.value)}
            rows={5}
            variant="outlined"
          />
        </Box>
      </Box>

      <UnifiedButton text="send_feedback" onClick={handleSendFeedback} />
    </Box>
  );
};
