import React, {
  MutableRefObject,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCallControllsOnRight,
  selectCallNumber,
  selectCallSettings,
  setCallClearSetup,
  setCallDuration,
  setCallEndBy,
  setCallError,
  toggleCallCamera,
  toggleCallMicrophone,
} from "../../redux/callSlice";
import * as awrtc from "../../awrtc";
import { Box, Typography } from "@mui/material";
import { CALL_OPTIONS } from "../../enums/serverURL";
import { useNavigate } from "react-router-dom";
import {
  selectUserSettings,
  selectUserStage,
  setUserClearSetup,
  setUserStage,
} from "../../redux/userSlice";
import { UserAppStep } from "../../enums/userAppStep";
import { Reconnect } from "./CallRoom-Reconnect";
import { Loader } from "../Loader-component/Loader";
import { CallControlls } from "./CallRoom-Controlls";
import RemoteVideoComponent from "./CallRoom-RemoteVideoComponent";
import { LocalVideoComponent } from "./CallRoom-LocalVideoComponent";
import { CALL_END_BY } from "../../enums/callEndBy";
import { useTranslation } from "react-i18next";
import { DEVICES } from "../../enums/devices";

export interface MediaState {
  AudioState: boolean;
  VideoState: boolean;
  Type: string;
}

export const CallRoom = ({
  StreamRef,
}: {
  StreamRef: MutableRefObject<MediaStream | null>;
}) => {
  const { t } = useTranslation();
  const userSettings = useSelector(selectUserSettings);
  const clientCurrentSettings = useSelector(selectCallSettings);
  const { camera, microphone, speaker } = clientCurrentSettings;
  const roomCode = useSelector(selectCallNumber);
  const userStage = useSelector(selectUserStage);
  const isCallControllsOnRight = useSelector(selectCallControllsOnRight);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  //instances
  //INIT CONFIG
  const mediaConfig = useMemo(() => {
    const config = new awrtc.MediaConfig();
    config.Audio = microphone;
    config.Video = camera;
    config.IdealWidth = 1280;
    config.IdealHeight = 760;
    config.MaxFps = 30;
    config.VideoDeviceName = userSettings.userCameraInfo.label;
    return config;
  }, [microphone, camera, userSettings.userCameraInfo.label]);

  useEffect(() => {
    awrtc.DeviceApi.UpdateAsync();
  }, [mediaConfig]);

  // INIT NETWORK
  const netConfig = useMemo(() => {
    const config = new awrtc.NetworkConfig();
    config.IceServers = [
      {
        urls: CALL_OPTIONS.ICE_SERVER_URL,
        username: CALL_OPTIONS.ICE_SERVER_USER,
        credential: CALL_OPTIONS.ICE_SERVER_PASSWORD,
      },
    ];
    config.IsConference = false;
    config.SignalingUrl = CALL_OPTIONS.SIGNAL_SERVER_URL;
    config.KeepSignalingAlive = true;
    config.MaxIceRestart = 0;
    return config;
  }, []);

  const callInstance = useMemo(
    () => new awrtc.BrowserWebRtcCall(netConfig),
    []
  );

  const remoteStreamRef = useRef<MediaStream | null>(null);
  const localStreamRef = useRef<MediaStream | null>(StreamRef?.current);
  const localVideoRef = useRef<HTMLVideoElement | null>(null);
  const remoteVideoRef = useRef<HTMLVideoElement | null>(null);

  const callReconnectTimerRef = useRef<NodeJS.Timeout | null>(null);
  const callUpdateIntervalId = useRef<NodeJS.Timeout | null>(null);
  const timerId = useRef<NodeJS.Timeout | null>(null);
  const avalibleVideoDevices = useRef<awrtc.DeviceApi | null>(
    awrtc.DeviceApi.GetVideoDevices()
  );

  const [isCallable, setIsCallable] = useState(false);
  const [connectionId, setConnectionId] = useState<awrtc.ConnectionId | null>(
    null
  );
  const [loading, setLoading] = useState(true);
  const [waitingPage, setWaitingPage] = useState(false);
  const [callTimer, setCallTimer] = useState(0);
  const [mediaState, setMediaState] = useState<MediaState>({
    VideoState: true,
    AudioState: true,
    Type: "0",
  });

  useEffect(() => {
    document.body.scrollTo({top: 0})
  }, [])

  // const [reconnecting, setReconnecting] = useState(false);

  // useEffect(() => {
  //   return () => {
  //     if (callReconnectTimerRef.current) {
  //       clearTimeout(callReconnectTimerRef.current);
  //       callReconnectTimerRef.current = null;
  //     }
  //     reconnectAttemptCount.current = 0;
  //     setReconnecting(false);
  //   };
  // }, []);

  useEffect(() => {
    awrtc.DeviceApi.UpdateAsync().then(() => {
      startCall();
    });

    return () => {
      if (userStage === UserAppStep.END_CALL) {
        disconnectCall();
        setConnectionId(null);
        window.sessionStorage.setItem("userStage", UserAppStep.END_CALL);
      }
    };
  }, []);

  useEffect(() => {
    const handleBeforeUnload = () => {
      sessionStorage.setItem("reloadState", "Reloaded");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);

      if (window.sessionStorage.getItem("reloadState") === "Reloaded") {
        clearInterval(callUpdateIntervalId.current);
      }
    };
  }, []);

  useEffect(() => {
    if (callInstance) {
      callInstance.Configure(mediaConfig);
    }
  }, [camera, microphone, avalibleVideoDevices, callInstance, mediaConfig]);

  useEffect(() => {
    if (localVideoRef.current) {
      localVideoRef.current.muted = true;
    }
  }, [microphone, camera]);

  ///START CALL
  const startCall = async () => {
    if (isCallable) return;
    console.warn("Starting call...");

    callInstance.addEventListener(handleEvent);

    if (awrtc.DeviceApi.GetVideoDevices()[0]) {
      callInstance.Configure(mediaConfig);
    }

    callInstance.Call(roomCode);

    if (!callUpdateIntervalId.current) {
      callUpdateIntervalId.current = setInterval(() => {
        callInstance.Update();
      }, 50);
    }
  };
  ///END CALL

  const startTimer = () => {
    timerId.current = setInterval(() => {
      setCallTimer((prevTimer) => prevTimer + 1);
    }, 1000);
  };

  const stopTimer = () => {
    if (timerId.current) {
      clearInterval(timerId.current);
    }
  };

  ///START RECONNECT
  // const MAX_RECONNECT_ATTEMPTS = 5;
  // // Reconnect attempt count
  // const reconnectAttemptCount = useRef(0);

  // const startReconnect = () => {
  //   if (reconnecting) return; // Prevent overlapping reconnect attempts

  //   if (reconnectAttemptCount.current >= MAX_RECONNECT_ATTEMPTS) {
  //     handleReconnectFailure();
  //     return;
  //   }

  //   setReconnecting(true); // Set reconnecting flag
  //   if (!connectionId) {
  //     dispatch(setUserStage(UserAppStep.RECONNECTING)); // Ensure this is set
  //     setWaitingPage(true);
  //   }

  //   if (callReconnectTimerRef.current) {
  //     clearTimeout(callReconnectTimerRef.current);
  //   }

  //   callReconnectTimerRef.current = setTimeout(() => {
  //     reconnectAttemptCount.current += 1;
  //     console.warn(`Reconnect attempt ${reconnectAttemptCount.current}`);
  //     startCall();
  //   }, 7000);
  // };

  // const handleReconnectFailure = () => {
  //   console.error("Failed to reconnect after several attempts.");
  //   dispatch(setCallEndBy(CALL_END_BY.BY_APP_ERROR));
  //   dispatch(setUserStage(UserAppStep.END_CALL));
  //   navigate("/checkout");
  // };

  // // Ensure cleanup on unmount or when ending call
  // useEffect(() => {
  //   return () => {
  //     // Clear reconnect attempts and timers
  //     if (callReconnectTimerRef.current) {
  //       clearTimeout(callReconnectTimerRef.current);
  //       callReconnectTimerRef.current = null;
  //     }
  //     reconnectAttemptCount.current = 0;
  //   };
  // }, []);
  ///END RECONNECT

  //CALL EVENTS START ========//
  const handleEvent = (sender: any, args: awrtc.CallEventArgs) => {
    if (args instanceof awrtc.CallEventArgs) {
      switch (args.Type) {
        case awrtc.CallEventType.CallAccepted:
          console.log("Event: CallAccepted");
          clearTimeout(callReconnectTimerRef.current);
          callReconnectTimerRef.current = null;
          setIsCallable(true);
          setWaitingPage(false);
          const callAcceptedArgs = args as awrtc.CallAcceptedEventArgs;
          console.log("Call accepted with id:", callAcceptedArgs.ConnectionId);
          const connectionId = callAcceptedArgs.ConnectionId;
          setConnectionId(connectionId);
          startTimer();
          dispatch(setUserStage(UserAppStep.ON_CALL));
          break;

        case awrtc.CallEventType.Invalid:
          console.log("Event: Invalid");
          console.error("Invalid room code");
          setWaitingPage(true);
          dispatch(
            setCallError(
              `${t("we_couldnt_find_anyone_with_a_matching_pin")} \n` +
                `${t("your_pin_is")} "${roomCode}". \n` +
                `${t("please_double_check_your_pin")} \n`
            )
          );
          break;

        case awrtc.CallEventType.MediaUpdate:
          console.log("Event: MediaUpdate");
          handleMediaUpdate(args as awrtc.MediaUpdatedEventArgs);
          break;

        case awrtc.CallEventType.ConnectionFailed:
        case awrtc.CallEventType.ListeningFailed:
          console.log("Event: ConnectionFailed or ListeningFailed");
          const errorDetails = args as awrtc.ErrorEventArgs;
          callInstance.removeEventListener(handleEvent);
          // startReconnect();
          console.error(
            "Connection failed. Error details:",
            errorDetails.ErrorMessage
          );
          if (errorDetails.ErrorMessage === "Connection failed.") {
            dispatch(setUserStage(UserAppStep.RECONNECTING));
            setWaitingPage(true);
            stopTimer();

            const reloadState = window.sessionStorage.getItem("reloadState");
            console.error(
              `Error details: Type: ${errorDetails.ErrorType}, Message: ${errorDetails.ErrorMessage}`
            );

            if (errorDetails.Type === 6) {
              dispatch(
                setCallError(
                  `${t("we_couldnt_find_anyone_with_a_matching_pin")} \n` +
                    `${t("your_pin_is")} "${roomCode}". \n` +
                    `${t("please_double_check_your_pin")} \n`
                )
              );
            }

            dispatch(
              setCallEndBy(!reloadState ? CALL_END_BY.BY_APP_ERROR : null)
            );
          }
          break;

        case awrtc.CallEventType.CallEnded:
          console.log("Event: CallEnded");
          dispatch(setCallEndBy(CALL_END_BY.BY_REMOTE));
          dispatch(setCallDuration(callTimer));
          dispatch(setUserStage(UserAppStep.END_CALL));
          window.sessionStorage.setItem("userStage", UserAppStep.END_CALL);
          disconnectCall();
          navigate("/checkout");
          break;

        case awrtc.CallEventType.Message:
          console.log("Event: Message");
          const messageArgs = args as awrtc.MessageEventArgs;
          const message = JSON.parse(messageArgs.Content);
          setMediaState({
            AudioState: message.AudioState,
            VideoState: message.VideoState,
            Type: message.Type,
          });
          break;

        case awrtc.CallEventType.DataMessage:
          console.log("Event: DataMessage");
          const dataMessage = args as awrtc.DataMessageEventArgs;
          console.warn("Data message content:", dataMessage.Content);
          break;

        case awrtc.CallEventType.ConfigurationComplete:
          console.log("Event: ConfigurationComplete");
          break;

        default:
          console.log("Unhandled call event:", args.Type);
          break;
      }
    }
  };
  //CALL EVENTS END ========//

  const handleMediaUpdate = (args: awrtc.MediaUpdatedEventArgs) => {
    const videoElement = args.VideoElement;
    const { ConnectionId } = args;

    if (ConnectionId.id === -1) {
      if (localVideoRef.current) {
        localVideoRef.current.srcObject = videoElement.srcObject;
        localVideoRef.current.muted = true;
      }
    } else {
      if (remoteVideoRef.current) {
        setWaitingPage(false);
        setLoading(false);
        const stream = videoElement.srcObject as MediaStream;
        if (stream) {
          remoteStreamRef.current = stream;
          remoteVideoRef.current.srcObject = stream;
        }
      }
    }
  };

  const toggleDeviceCheck = async (device: string) => {
    try {
      console.log(`Toggling device: ${device}`);
      if (device === DEVICES.CAMERA) {
        if (callInstance) {
          callInstance.Send(
            JSON.stringify({
              VideoState: !camera,
              AudioState: microphone,
              Type: 0,
            }),
            true,
            connectionId
          );
        }
        dispatch(toggleCallCamera());
      } else if (device === DEVICES.MICROPHONE) {
        if (callInstance) {
          callInstance.Send(
            JSON.stringify({
              VideoState: camera,
              AudioState: !microphone,
              Type: 0,
            }),
            true,
            connectionId
          );
        }
        dispatch(toggleCallMicrophone());
      }
    } catch (error) {
      console.error("Error in toggleDeviceCheck function:", error);
    }
  };

  const disconnectCall = async () => {
    stopTimer();

    if (callInstance) {
      console.warn("Ending call...");

      callInstance.removeEventListener(handleEvent);
      callInstance.Dispose();

      clearInterval(callReconnectTimerRef.current);
      callReconnectTimerRef.current = null;

      callUpdateIntervalId.current = null;
      timerId.current = null;

      localVideoRef.current = null;
      remoteVideoRef.current = null;
      remoteStreamRef.current = null;
      localStreamRef.current = null;

      // Dispose media stream and clean up
      mediaConfig.Audio = false;
      mediaConfig.Video = false;
    }

    // Clear any remaining intervals or timeouts
    const intervalId = await window.setInterval(() => {},
    Number.MAX_SAFE_INTEGER);
    for (let i = 1; i < intervalId; i++) {
      window.clearInterval(i);
    }
    stopAllMediaStreams();

    // Ensure any reconnect attempts are stopped
    if (callReconnectTimerRef.current) {
      clearTimeout(callReconnectTimerRef.current);
      callReconnectTimerRef.current = null;
    }
  };

  function stopAllMediaStreams() {
    const mediaElements = document.querySelectorAll<
      HTMLVideoElement | HTMLAudioElement
    >("video, audio");

    mediaElements.forEach(async (mediaElement) => {
      const stream = mediaElement.srcObject as MediaStream | null;

      if (stream) {
        await stream.getTracks().forEach((track) => {
          if (track.readyState === "live") {
            track.stop();
          }
        });
      }
    });
  }

  const handleEndCall = () => {
    disconnectCall();
    dispatch(setCallEndBy(CALL_END_BY.BY_LOCAL));
    dispatch(setCallDuration(callTimer));
    dispatch(setUserStage(UserAppStep.END_CALL));
    window.sessionStorage.setItem("userStage", UserAppStep.END_CALL);

    // Navigate based on session state
    const callNumber = window.sessionStorage.getItem("callNumber");
    const userStage = window.sessionStorage.getItem("userStage");
    if (callNumber && userStage === UserAppStep.END_CALL && isCallable) {
      navigate("/checkout");
    } else {
      dispatch(setCallClearSetup());
      dispatch(setUserClearSetup());
      navigate("/");
    }
  };

  return (
    <Box
      position="relative"
      boxSizing="border-box"
      overflow={"hidden"}
      style={{
        display: "flex",
        height: "100%",
        width: "100vw",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }}
      >
        {loading && !waitingPage && (
          <Box
            position="absolute"
            height="100%"
            sx={{
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "2rem",
              textAlign: "center",
            }}
          >
            <Typography variant="h6">Waiting for others</Typography>
            <Loader />
          </Box>
        )}
        {waitingPage && (
          <Box
            position="absolute"
            sx={{
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: "20",
            }}
          >
            <Reconnect isCallable={isCallable} handleEndCall={handleEndCall} />
          </Box>
        )}
        <RemoteVideoComponent
          mediaState={mediaState}
          remoteVideoRef={remoteVideoRef}
        />
        <LocalVideoComponent
          microphone={microphone}
          camera={camera}
          loading={loading}
          localVideoRef={localVideoRef}
          userStage={userStage}
          isCallControllsOnRight={isCallControllsOnRight}
        />
        <CallControlls
          isLoading={loading}
          toggleDeviceCheck={toggleDeviceCheck}
          handleEndCall={handleEndCall}
          microphone={microphone}
          camera={camera}
          speaker={speaker}
          seconds={callTimer}
        />
      </Box>
    </Box>
  );
};
