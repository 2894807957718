import React from "react";
import { Button } from "@mui/material";
import { COLOR } from "../../enums/styles";
import { useTranslation } from "react-i18next";
import { useWindowWidth } from "../../helpers/useWindowWidth";

interface UnifiedButtonProps {
  text: string;
  onClick: () => void;
  sx?: any;
  otherProps?: any;
  variant?: any
}

export const UnifiedButton: React.FC<UnifiedButtonProps> = ({
  text,
  onClick,
  sx,
  variant,
}) => {
  const { t } = useTranslation();
  const windowWidth = useWindowWidth()

  return (
    <Button
      size={windowWidth < 500 ? "small" : "large"}
      variant={variant || "contained"}
      sx={{
        backgroundColor: COLOR.PURPLE,
        ":hover": {
          backgroundColor: "white",
          color: COLOR.PURPLE,
        },
        color: "white",
        fontWeight: "700",
        ...sx,
      }}
      onClick={onClick}
    >
      {t(text)}
    </Button>
  );
};
