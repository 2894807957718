export const LANGUAGES = [
  { code: "en", name: "English" },
  { code: "de", name: "Deutsch" },
];

//Emailer URL SENDER
export const EMAILER_URL =
  "https://us-central1-brainssistance.cloudfunctions.net/sendEmail";

//Emailer URL RECIPIENT
export const RECIPIENT_URL = "feedback-videocall@brainssistance.com"
