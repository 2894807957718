import {
  Box,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setCallNumber } from "../../redux/callSlice";
import { useNavigate } from "react-router-dom";
import { setUserStage } from "../../redux/userSlice";
import { UserAppStep } from "../../enums/userAppStep";
import { InputFields } from "./InputFields-component";

export const StartCall = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [digits, setDigits] = useState<string[]>(Array(6).fill(""));
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const [disabledInputs, setDisabledInputs] = useState(false);

  useEffect(() => {
    const handleBackspace = (event: KeyboardEvent) => {
      if (event.key === "Backspace") {
        const activeElement = document.activeElement as HTMLInputElement;
        const currentIndex = inputRefs.current.findIndex(
          (ref) => ref === activeElement
        );

        if (currentIndex >= 0) {
          if (digits[currentIndex] !== "") {
            const newDigits = [...digits];
            newDigits[currentIndex] = "";
            setDigits(newDigits);
          }

          if (currentIndex > 0) {
            inputRefs.current[currentIndex - 1]?.focus();
          }
        }
      }
    };

    document.addEventListener("keydown", handleBackspace);

    return () => {
      document.removeEventListener("keydown", handleBackspace);
    };
  }, [digits]);

  useEffect(() => {
    const emptyInputIndex = digits.findIndex((digit) => digit === "");
    const focusIndex =
      emptyInputIndex === -1 ? digits.length - 1 : emptyInputIndex;
    inputRefs.current[focusIndex]?.focus();
  }, [digits]);

  const handleChange = (index: number, value: string) => {
    if (/^\d*$/.test(value) && value.length <= 1) {
      setDigits((prevDigits) => {
        const newDigits = [...prevDigits];
        newDigits[index] = value;

        if (value.length > 0 && index < digits.length - 1) {
          inputRefs.current[index + 1]?.focus();
        }

        return newDigits;
      });
    }
  };

  const handleJoinCall = async () => {
    const enteredDigits = digits.join("");
    if (enteredDigits.length < 6) {
      return;
    }

    dispatch(setCallNumber(enteredDigits));
    window.sessionStorage.setItem("callNumber", enteredDigits);
    setDisabledInputs(true);

    const hasPermissions = await checkPermissions();
    if (hasPermissions) {
      navigate("/meet");
      dispatch(setUserStage(UserAppStep.PREPARING));
    } else {
      dispatch(setUserStage(UserAppStep.PERMISSION));
      navigate("/error");
    }
  };

  const checkPermissions = async (): Promise<any> => {
    try {
      await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
      return true;
    } catch (error) {
      console.error("Permission check failed:", error);
      return false;
    }
  };

  useEffect(() => {
    const enteredDigits = digits.join("");
    if (enteredDigits.length === 6) {
      handleJoinCall();
    }
  }, [digits]);

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      width={isMobile ? "100%" : "400px"}
      gap="20px"
      p={isMobile ? "0 10px" : "0"}
    >
      <Typography>{t("explanation")}</Typography>
      <Box>
        <Typography textAlign="center">
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. In unde
          provident iste aspernatur repellat necessitatibus sed voluptatum.
        </Typography>
      </Box>
      <Box display="flex" gap={isMobile ? 1 : 3}>
        <InputFields
          digits={digits}
          inputRefs={inputRefs}
          disabledInputs={disabledInputs}
          handleChange={handleChange}
          isMobile={isMobile}
        />
      </Box>
    </Box>
  );
};
