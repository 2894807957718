import React from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { VideocamOff } from "@mui/icons-material";
import { UserAppStep } from "../../enums/userAppStep";
import { useDeviceOrientation } from "../../helpers/useDeviceOrientation";
import { useWindowWidth } from "../../helpers/useWindowWidth";
import MicOffIcon from "@mui/icons-material/MicOff";

export const LocalVideoComponent = ({
  camera,
  isCallControllsOnRight,
  userStage,
  loading,
  localVideoRef,
  microphone,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isPortrait = useDeviceOrientation();
  const windowWidth = useWindowWidth();

  // Determine the size of the video component based on screen size
  const videoWidth = (isMobile && !isPortrait && windowWidth < 700) ? 150 : 300;
  const videoHeight = (isMobile && !isPortrait && windowWidth < 700) ? 80 : 200;

  // Define common styles
  const commonStyles = {
    position: "absolute",
    zIndex: 10,
    width: `${videoWidth}px`,
    height: `${videoHeight}px`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  // Visibility handling
  const visibleStyles = {
    visibility: userStage === UserAppStep.RECONNECTING || loading ? "hidden" : "visible",
  };

  // Positioning based on control placement
  const rightStyles = isCallControllsOnRight
    ? { bottom: "5px", right: "80px" }
    : { bottom: "80px", right: "5px" };

  return (
    <Box sx={{ ...commonStyles, ...visibleStyles, ...rightStyles }}>
      {camera ? (
        <Box sx={{ position: "relative", width: "100%", height: "100%" }}>
          <video
            style={{ width: "100%", height: "100%", objectFit: "cover", borderRadius: "5px" }}
            ref={localVideoRef}
            autoPlay
            playsInline
          />
          {!microphone && (
            <Box
              sx={{
                position: "absolute",
                zIndex: 15,
                bottom: 0,
                left: "50%",
                transform: "translateX(-50%)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "100%",
              }}
            >
              <MicOffIcon sx={{ fontSize: "1.5rem", color: "red" }} />
            </Box>
          )}
        </Box>
      ) : (
        <Box
          sx={{
            position: "absolute",
            backgroundColor: "black",
            color: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
            ...visibleStyles,
          }}
        >
          {microphone ? (
            <VideocamOff sx={{ fontSize: "1.5rem", color: "red" }} />
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 1,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <VideocamOff sx={{ fontSize: "1.5rem", color: "red" }} />
              <MicOffIcon sx={{ fontSize: "1.5rem", color: "red" }} />
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};
