import React, { useEffect, useState, useRef } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  MediaPermissionsError,
  MediaPermissionsErrorType,
  requestMediaPermissions,
} from "mic-check";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import * as awrtc from "../../awrtc";
import { DEVICES } from "../../enums/devices";

export enum PermissionErrorsText {
  DeniedMicrophone = "You have explicitly denied permission to access the microphone devices.",
  NoMicrophone = "You have not connected any microphone yet.",
  MicrophoneInUse = "Your microphone is in use by another app.",
 
  NoCamera = "You have not connected any camera yet.",
  DeniedCamera = "You have explicitly denied permission to access the camera devices.",
  CameraInUse = "Your camera is in use by another app.",

  OverconstrainedError = "A device is available but cannot be used with this app due to settings.",
  UnhandledErrorText = "Something went wrong. Device issue. Please try again.",
  
  UserDeniedAllPermissions = "You denied permission to access the media devices.",
}

export const Permission: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [errorMessagesMic, setErrorMessagesMic] = useState("");
  const [errorMessagesCamera, setErrorMessagesCamera] = useState("");

  const handleOverconstrainedError = (error: any) => {
    if (error.name === "OverconstrainedError") {
      return PermissionErrorsText.OverconstrainedError;
    }
    return PermissionErrorsText.UnhandledErrorText;
  };

  const getVideoInput = async (): Promise<string> => {
    const videoInputs = await navigator.mediaDevices
      .enumerateDevices()
      .then((res) => res.filter((item) => item.kind === DEVICES.CAMERA));

    if (videoInputs.length === 0) {
      return PermissionErrorsText.NoCamera;
    }

    if (videoInputs[0].deviceId === "") {
      return PermissionErrorsText.DeniedCamera;
    }

    for (const item of videoInputs) {
      try {
        await navigator.mediaDevices.getUserMedia({
          video: { deviceId: { exact: item.deviceId } },
          audio: false,
        });
        return ""; // No errors
      } catch (error) {
        if (error.name === "NotAllowedError" || error.name === "NotReadableError") {
          return PermissionErrorsText.CameraInUse;
        }
        return handleOverconstrainedError(error);
      }
    }

    return PermissionErrorsText.UnhandledErrorText;
  };

  const getAudioInput = async (): Promise<string> => {
    const audioInputs = await navigator.mediaDevices
      .enumerateDevices()
      .then((res) => res.filter((item) => item.kind === DEVICES.MICROPHONE));

    if (audioInputs.length === 0) {
      return PermissionErrorsText.NoMicrophone;
    }

    if (audioInputs[0].deviceId === "") {
      return PermissionErrorsText.DeniedMicrophone;
    }

    for (const item of audioInputs) {
      try {
        await navigator.mediaDevices.getUserMedia({
          video: false,
          audio: { deviceId: { exact: item.deviceId } },
        });
        return ""; // No errors
      } catch (error) {
        if (error.name === "NotAllowedError" || error.name === "NotReadableError") {
          return PermissionErrorsText.MicrophoneInUse;
        }
        return handleOverconstrainedError(error);
      }
    }

    return PermissionErrorsText.UnhandledErrorText;
  };

  useEffect(() => {
    const getMediaDevices = async () => {
      try {
        console.log(await navigator.mediaDevices.enumerateDevices());
        await awrtc.DeviceApi.UpdateAsync();

        const cameraError = await getVideoInput();
        const micError = await getAudioInput();

        if (micError) {
          setErrorMessagesMic(micError);
        }

        if (cameraError) {
          setErrorMessagesCamera(cameraError);
        }
      } catch (error) {
        console.error("Error retrieving media devices:", error);
        setErrorMessagesCamera(PermissionErrorsText.UnhandledErrorText);
      }
    };

    getMediaDevices();
  }, []);

  const isDeniedAll =
    errorMessagesCamera === PermissionErrorsText.DeniedCamera &&
    errorMessagesMic === PermissionErrorsText.DeniedMicrophone;

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      width="400px"
      gap="20px"
      textAlign="center"
      mt={5}
    >
      <Typography variant="h6">{t("explanation")}</Typography>
      <Box>
        <Typography>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. In unde
          provident iste aspernatur repellat necessitatibus sed voluptatum
          accusantium laboriosam vitae.
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column" gap={2}>
        {!isDeniedAll ? (
          <>
            <Typography variant="h6" color={"red"}>{errorMessagesMic}</Typography>
            <Typography variant="h6" color={"red"}>{errorMessagesCamera}</Typography>
          </>
        ) : (
          <Typography variant="h6" color={"red"}>{PermissionErrorsText.UserDeniedAllPermissions}</Typography>
        )}
      </Box>
    </Box>
  );
};
