import { Box, TextField } from '@mui/material'
import React from 'react'

export const InputFields = ({ digits, inputRefs, disabledInputs, isMobile, handleChange}) => {
  return (
    <>{digits.map((digit: string, index: number) => (
        <Box
          key={index}
          display="inline-block"
          border="2px solid black"
          borderRadius="5px"
          p="5px"
        >
          <TextField
            type="number"
            inputRef={(el) => (inputRefs.current[index] = el)}
            variant="outlined"
            value={digit}
            onChange={(e) => handleChange(index, e.target.value)}
            disabled={disabledInputs}
            style={{
              overflow: "hidden",
              width: isMobile ? 35 : 50,
              textAlign: "center",
              fontSize: isMobile ? "15px" : "20px",
              fontWeight: "bold",
            }}
            inputProps={{
              style: {
                textAlign: "center",
                fontSize: isMobile ? "20px" : "25px",
                fontWeight: "700",
                padding: isMobile ? "15px 8px" : "16px 14px",
                backgroundColor: "transparent",
              },
              autoComplete: "off",
              autoCorrect: "off",
              spellCheck: "false",
              list: "none"
            }}
          />
        </Box>
      ))}</>
  )
}