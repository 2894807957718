import { configureStore } from '@reduxjs/toolkit';
import callSlice from './callSlice';
import userSlice from './userSlice';


const store = configureStore({
  reducer: {
    callSlice: callSlice,
    userSlice: userSlice,
  },
});


export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store;
