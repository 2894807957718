import { Box } from '@mui/material'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setUserStage } from '../redux/userSlice'
import { UserAppStep } from '../enums/userAppStep'
import { StartCall } from '../components/StartCall-component/StartCall'

export const StartCallPage = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setUserStage(UserAppStep.LOGIN))
  }, [])
  
  return (
    <Box display="flex" alignItems="center" justifyContent="center" minHeight="400px">
       <StartCall />
    </Box>
  )
}
