import {
  Box,
  FormControl,
  MenuItem,
  Select,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { selectCallLanguage, setCallLanguage } from "../../redux/callSlice";
import { COLOR } from "../../enums/styles";
import { selectUserStage } from "../../redux/userSlice";
import { UserAppStep } from "../../enums/userAppStep";

export const Header = ({ cleanUp }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const language = useSelector(selectCallLanguage);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const userStage = useSelector(selectUserStage);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (
      userStage === UserAppStep.LOGIN ||
      userStage === UserAppStep.PREPARING ||
      userStage === UserAppStep.END_CALL ||
      userStage === UserAppStep.PERMISSION
    ) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, [userStage]);

  const handleLanguageChange = (event) => {
    const lang = event.target.value;
    dispatch(setCallLanguage(lang));
  };

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [i18n, language]);

  return (
    <Box
      visibility={isVisible ? "visible" : "hidden"}
      position="fixed"
      top="0"
      left="0"
      zIndex="5"
      height="75px"
      width="100%"
      sx={{ backgroundColor: COLOR.PURPLE, color: "white" }}
    >
      <Box
        padding={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          gap="20px"
        >
          <Link
            to="/"
            onClick={cleanUp}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "20px",
              textDecoration: "none",
              color: "white",
            }}
          >
            <img
              style={{ width: "40px", height: "40px" }}
              alt="logo"
              src="/LogoBrainssistanceWhite.png"
            />

            <Typography variant="h6" component="div" fontWeight="700">
              Video Call
            </Typography>
          </Link>
        </Box>

        <FormControl>
          <ToggleButtonGroup
            size="small"
            value={language}
            exclusive
            onChange={handleLanguageChange}
            sx={{
              width: "140px",
              backgroundColor: "white",
              ":hover": {
                color: COLOR.GRAY,
              },
            }}
          >
            <ToggleButton
            fullWidth
              value="en"
              sx={{
                color: language === "en" ? COLOR.PURPLE : COLOR.GRAY,
                fontWeight: "700",
                "&.Mui-selected": {
                  color: COLOR.PURPLE,
                  backgroundColor: "white",
                },
              }}
            >
              EN
            </ToggleButton>
            <ToggleButton
            fullWidth
              value="de"
              sx={{
                color: language === "de" ? COLOR.PURPLE : COLOR.GRAY,
                fontWeight: "700",
                "&.Mui-selected": {
                  color: COLOR.PURPLE,
                  backgroundColor: "white",
                },
              }}
            >
              DE
            </ToggleButton>
          </ToggleButtonGroup>
        </FormControl>
      </Box>
    </Box>
  );
};
