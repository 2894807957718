import React from "react";
import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import {
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useWindowWidth } from "../../helpers/useWindowWidth";

interface DeviceSelectProps {
  label: string;
  value: string;
  onChange: (event: SelectChangeEvent<string>) => void;
  onToggleCheck: () => void;
  enabled: boolean;
  devices: MediaDeviceInfo[];
  deviceType: string;
  isMobile: boolean;
  isPortrait: boolean;
}

export const DeviceSelect: React.FC<DeviceSelectProps> = ({
  label,
  value,
  onChange,
  onToggleCheck,
  enabled,
  devices,
  deviceType,
  isMobile,
  isPortrait,
}) => {
  const { t } = useTranslation();
  const windowWidth = useWindowWidth();
  const portraitMode = !isPortrait && isMobile ? 450 : 300;

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    onChange(event);
  };

  const defaultOption = `Default ${t(label)}`;
  const isValidValue = devices.some((device) => device.deviceId === value);

  return (
    <FormControl>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <InputLabel id={`${deviceType}-label`}>{t(label)}</InputLabel>
        <Box
          display="flex"
          alignItems="center"
          width={
            isMobile ? `${portraitMode}px` : windowWidth > 1100 ? "540px" : "400px"
          }
        >
          <Select
            style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "pre" }}
            size={isMobile ? "small" : "medium"}
            sx={{ flexGrow: 1 }}
            label={t(label)}
            labelId={`${deviceType}-label`}
            value={isValidValue ? value : defaultOption}
            onChange={handleSelectChange}
          >
            {devices.length > 0 ? (
              devices.map((device) => (
                <MenuItem
                  sx={{ whiteSpace: "normal" }}
                  key={device.deviceId}
                  value={device.deviceId}
                >
                  {device.label}
                </MenuItem>
              ))
            ) : (
              <MenuItem value={defaultOption}>{defaultOption}</MenuItem>
            )}
          </Select>
          <IconButton
            onClick={onToggleCheck}
            sx={{
              marginLeft: 1,
              padding: isMobile ? "4px" : "8px",
              width: "auto",
            }}
          >
            {enabled ? (
              <CheckCircleIcon sx={{ color: "green" }} />
            ) : (
              <CancelIcon sx={{ color: "red" }} />
            )}
          </IconButton>
        </Box>
      </Box>
    </FormControl>
  );
};
