import { DEVICES } from "../../enums/devices";
import { COLOR } from "../../enums/styles";
import {
  Mic,
  MicOff,
  Videocam,
  VideocamOff,
  CallEnd,
} from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import { useSelector } from "react-redux";
import { selectCallControllsOnRight } from "../../redux/callSlice";
import { formatTime } from "../../helpers/helpers";

export const CallControlls = ({
  toggleDeviceCheck,
  handleEndCall,
  microphone,
  camera,
  speaker,
  seconds,
  isLoading,
}) => {
  const isCallControllsOnRight = useSelector(selectCallControllsOnRight);

  return (
    <Box
      sx={
        isCallControllsOnRight
          ? {
              position: "fixed",
              right: "10px",
              top: "50%",
              transform: "translateY(-50%)",
              display: "flex",
              flexDirection: "column",
              gap: 2,
              zIndex: "11",
            }
          : {
              position: "fixed",
              bottom: "10px",
              left: "50%",
              transform: "translateX(-50%)",
              display: "flex",
              flexDirection: "row",
              gap: 2,
              zIndex: "11",
            }
      }
    >
      <IconButton
        disabled={isLoading}
        size="large"
        onClick={() => toggleDeviceCheck(DEVICES.MICROPHONE)}
        sx={{
          color: "white",
          "&:hover": {
            color: COLOR.PURPLE,
            backgroundColor: "white",
          },
        }}
      >
        {microphone ? <Mic /> : <MicOff />}
      </IconButton>

      <IconButton
        disabled={isLoading}
        size="large"
        onClick={() => toggleDeviceCheck(DEVICES.CAMERA)}
        sx={{
          color: "white",
          "&:hover": {
            color: COLOR.PURPLE,
            backgroundColor: "white",
          },
        }}
      >
        {camera ? <Videocam /> : <VideocamOff />}
      </IconButton>

      <IconButton
        size="large"
        onClick={handleEndCall}
        sx={{
          color: "red",
          "&:hover": {
            color: "red",
            backgroundColor: "white",
          },
        }}
      >
        <CallEnd />
      </IconButton>
      
      <Box sx={{ color: "white", alignSelf: "center" }}>
        {formatTime(seconds)}
      </Box>
    </Box>
  );
};
