import * as React from "react";
import CircularProgress, {
  CircularProgressProps,
} from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number }
) {
  const remainingTime = Math.max(Math.ceil((props.value / 100) * 60), 0);

  return (
    <Box
      sx={{
        position: "relative",
        display: "inline-flex",
        alignItems: "center",
      }}
    >

      <CircularProgress variant="determinate" {...props} />

      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >

        <Typography variant="caption" component="div" color="text.secondary">
          {remainingTime}
        </Typography>
        
      </Box>
    </Box>
  );
}

export default function TimerComponent() {
  const [progress, setProgress] = React.useState(100);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => Math.max(prevProgress - 100 / 60, 0));
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
      <CircularProgressWithLabel value={progress} />
      <Typography variant="body2" color="text.primary">
        Returning to home screen
      </Typography>
    </Box>
  );
}
