import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { COLOR } from "../../enums/styles";

const card = (
  <React.Fragment>
    <CardContent>

      <Typography variant="h5" component="div">
        Brainssistance
      </Typography>

      <Typography variant="body2">
        The holistic app ecosystem for rehabilitation, <br /> senior and
        disabled care.
      </Typography>
      
    </CardContent>
    <CardActions
      sx={{ display: "flex", justifyContent: "flex-end", paddingTop: 0 }}
    >
      <Button
        onClick={() => window.open("https://www.brainssistance.com/", "_blank")}
        size="small"
        sx={{
          color: COLOR.PURPLE,
        }}
      >
        Learn More
      </Button>
    </CardActions>
  </React.Fragment>
);

export default function AdvertisingCard() {
  return (
    <Box sx={{ minWidth: 275 }}>
      <Card variant="outlined">{card}</Card>
    </Box>
  );
}
