import {
  Box,
} from "@mui/material";
import { DEVICES } from "../../enums/devices";
import { DeviceSelect } from "./SetupCall-DeviceSelect";

export const DeviceSettings = ({
  isMobile,
  userSettings,
  handleCameraChange,
  handleSpeakerChange,
  handleMicrophoneChange,
  devices,
  toggleDeviceCheck,
  microphoneEnabled,
  cameraEnabled,
  speakerEnabled,
  isPortrait
}) => {

  const portraitMode = !isPortrait && isMobile ? 450 : 300
  return (
    <Box
      width={isMobile ? `${portraitMode}px` : "100%"}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems={isMobile ? "center" : "flex-start"}
      gap={2}
      p={1}
    >
      <DeviceSelect
        label="toggle_camera"
        value={userSettings.userCameraInfo.deviceId}
        onChange={handleCameraChange}
        onToggleCheck={() => toggleDeviceCheck(DEVICES.CAMERA)}
        enabled={cameraEnabled}
        devices={devices.filter((device) => device.kind === DEVICES.CAMERA)}
        deviceType={DEVICES.CAMERA}
        isMobile={isMobile}
        isPortrait={isPortrait}
      />

      <DeviceSelect
        label="toggle_microphone"
        value={userSettings.userMicrophoneInfo.deviceId}
        onChange={handleMicrophoneChange}
        onToggleCheck={() => toggleDeviceCheck(DEVICES.MICROPHONE)}
        enabled={microphoneEnabled}
        devices={devices.filter((device) => device.kind === DEVICES.MICROPHONE)}
        deviceType={DEVICES.MICROPHONE}
        isMobile={isMobile}
        isPortrait={isPortrait}
      />

      <DeviceSelect
        label="toggle_speakers"
        value={userSettings.userSpeakerInfo.deviceId}
        onChange={handleSpeakerChange}
        onToggleCheck={() => toggleDeviceCheck(DEVICES.SPEAKER)}
        enabled={speakerEnabled}
        devices={devices.filter((device) => device.kind === DEVICES.SPEAKER)}
        deviceType={DEVICES.SPEAKER}
        isMobile={isMobile}
        isPortrait={isPortrait}
      />
    </Box>
  );
};
