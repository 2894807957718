import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { CALL_END_BY } from "../enums/callEndBy";

export interface CallState {
  callNumber: string;
  callDuration: number;
  callSettings: {
    camera: boolean;
    microphone: boolean;
    speaker: boolean;
  };
  callControllsOnRight: boolean;
  callLanguage: string;
  callError: string;
  callEndBy: CALL_END_BY | null;
}

const initialState: CallState = {
  callNumber: "",
  callDuration: 0,
  callSettings: {
    camera: false,
    microphone: false,
    speaker: false,
  },
  callControllsOnRight: false,
  callLanguage: "en",
  callError: "",
  callEndBy: null,
};

export const callSlice = createSlice({
  name: "callSetup",
  initialState,
  reducers: {
    setCallEndBy: (state, action: PayloadAction<CALL_END_BY>) => {
      state.callEndBy = action.payload;
    },
    setCallSettings: (
      state,
      action: PayloadAction<{
        camera: boolean;
        microphone: boolean;
        speaker: boolean;
      }>
    ) => {
      state.callSettings = action.payload;
    },
    setCallDuration: (state, action: PayloadAction<number>) => {
      state.callDuration = action.payload;
    },
    toggleCallCamera: (state) => {
      state.callSettings.camera = !state.callSettings.camera;
    },
    toggleCallMicrophone: (state) => {
      state.callSettings.microphone = !state.callSettings.microphone;
    },
    toggleCallSpeaker: (state) => {
      state.callSettings.speaker = !state.callSettings.speaker;
    },
    setCallNumber: (state, action: PayloadAction<string>) => {
      state.callNumber = action.payload;
    },
    setCallLanguage: (state, action: PayloadAction<string>) => {
      state.callLanguage = action.payload;
    },
    setCallError: (state, action: PayloadAction<string>) => {
      state.callError = action.payload;
    },
    setCallControllsOnRight: (state, action: PayloadAction<boolean>) => {
      state.callControllsOnRight = action.payload;
    },
    setCallClearSetup: (state) => {
      state.callDuration = 0;
      state.callError = "";
      state.callNumber = "";
      state.callSettings = { camera: false, microphone: false, speaker: false };
    },
  },
});

export const {
  setCallEndBy,
  setCallClearSetup,
  setCallControllsOnRight,
  setCallNumber,
  setCallDuration,
  setCallSettings,
  setCallLanguage,
  toggleCallCamera,
  toggleCallMicrophone,
  toggleCallSpeaker,
  setCallError,
} = callSlice.actions;

export const selectCallDuration = (state: RootState) =>
  state.callSlice.callDuration;
export const selectCallError = (state: RootState) => state.callSlice.callError;
export const selectCallLanguage = (state: RootState) =>
  state.callSlice.callLanguage;
export const selectCallSettings = (state: RootState) =>
  state.callSlice.callSettings;
export const selectCallNumber = (state: RootState) =>
  state.callSlice.callNumber;
export const selectCallControllsOnRight = (state: RootState) =>
  state.callSlice.callControllsOnRight;
export const selectCallEndBy = (state: RootState) => state.callSlice.callEndBy;

export default callSlice.reducer;
