import { useEffect, useState } from 'react';

// Function to check if the device is a mobile device
export const isMobileDevice = () => 
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

export const useDeviceOrientation = (): boolean | null => {
  const [isPortrait, setIsPortrait] = useState<boolean | null>(null);

  useEffect(() => {
    if (!isMobileDevice()) {
      // If not a mobile device, do not add event listeners
      return;
    }

    const mediaQueryList = window.matchMedia("(orientation: portrait)");

    const updateOrientation = () => {
      setIsPortrait(mediaQueryList.matches);
    };

    // Add event listener
    mediaQueryList.addEventListener('change', updateOrientation);

    // Initial check
    updateOrientation();

    // Cleanup the event listener on component unmount
    return () => {
      mediaQueryList.removeEventListener('change', updateOrientation);
    };
  }, []);

  return isPortrait;
};
