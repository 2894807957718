import React, { useEffect, useRef } from "react";
import "./index.css";
import { Route, Routes, useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { StartCallPage } from "./pages/StartCallPage";
import { SetupCallPage } from "./pages/SetupCallPage";
import { Footer } from "./components/Footer-component/Footer";
import { Header } from "./components/Header-component/Header";
import { useDispatch, useSelector } from "react-redux";
import { selectUserStage, setUserStage } from "./redux/userSlice";
import { UserAppStep } from "./enums/userAppStep";
import { FeedbackPage } from "./pages/FeedbackPage";
import { setCallControllsOnRight, setCallNumber } from "./redux/callSlice";
import { CallRoomPage } from "./pages/CallRoomPage";
import { EndCallPage } from "./pages/EndCallPage";
import { useDeviceOrientation } from "./helpers/useDeviceOrientation";
import { useWindowWidth } from "./helpers/useWindowWidth";
import { cleanUp } from "./helpers/helpers";
import ProtectedRoute from "./helpers/ProtectedRoute";
import { ErrorPage } from "./pages/ErrorPage";

const App: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const localStreamRef = useRef<MediaStream | null>(null);
  const isPortrait = useDeviceOrientation();
  const windowWidth = useWindowWidth();
  const userStage = useSelector(selectUserStage);

  useEffect(() => {
    if (
      !isPortrait &&
      windowWidth > 600 &&
      userStage === UserAppStep.ON_CALL &&
      windowWidth < 1000
    ) {
      dispatch(setCallControllsOnRight(true));
    } else {
      dispatch(setCallControllsOnRight(false));
    }
  }, [isPortrait, userStage, windowWidth]);

  useEffect(() => {
    const sessionUserStage = window.sessionStorage.getItem("userStage");
    const sessionCallNumber = window.sessionStorage.getItem("callNumber");
    if (sessionCallNumber && sessionUserStage) {
      if (
        sessionUserStage === UserAppStep.ON_CALL ||
        sessionUserStage === UserAppStep.PREPARING
      ) {
        dispatch(setCallNumber(sessionCallNumber));
        dispatch(setUserStage(UserAppStep.PREPARING));
        navigate("/meet");
      }
    }
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100%",
        backgroundColor: "white",
      }}
    >
      <Header cleanUp={() => cleanUp(localStreamRef)} />
      <Box
        height="100%"
        sx={{
          padding: "80px 2rem",

          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Routes>
          <Route path="/" element={<StartCallPage />} />
          <Route
            path="/error"
            element={<ProtectedRoute element={<ErrorPage />} />}
          />
          <Route
            path="/meet"
            element={
              <ProtectedRoute
                element={<SetupCallPage localStreamRef={localStreamRef} />}
              />
            }
          />
          <Route
            path="/meet/:roomID"
            element={
              <ProtectedRoute
                element={
                  <CallRoomPage localStreamRef={localStreamRef.current} />
                }
              />
            }
          />
          <Route
            path="/checkout"
            element={<ProtectedRoute element={<EndCallPage />} />}
          />
          <Route
            path="/feedback"
            element={<ProtectedRoute element={<FeedbackPage />} />}
          />
        </Routes>
      </Box>
      <Footer cleanUp={() => cleanUp(localStreamRef)} />
    </Box>
  );
};

export default App;
