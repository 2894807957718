import { Box } from "@mui/material";
import { CallRoom } from "../components/CallRoom-component/CallRoom";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectUserStage } from "../redux/userSlice";
import { UserAppStep } from "../enums/userAppStep";
import { useNavigate } from "react-router-dom";


export const CallRoomPage = ({ localStreamRef }) => {
  const navigate = useNavigate();
  const isCall = useSelector(selectUserStage);
  useEffect(() => {
    if (isCall !== UserAppStep.ON_CALL) {
      navigate("/");
    }
  }, []);
  return (
    <Box position="absolute" height="100%" width="100%" right="0px" top="0px">
      <CallRoom StreamRef={localStreamRef} />
    </Box>
  );
};
