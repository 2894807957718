import React, { useEffect, useRef, useState } from "react";
import { Box, Typography } from "@mui/material";
import { COLOR } from "../../enums/styles";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectCallEndBy, selectCallNumber } from "../../redux/callSlice";
import { UnifiedButton } from "../UnifiedButton-component/UnifiedButton";
import { setUserStage } from "../../redux/userSlice";
import { UserAppStep } from "../../enums/userAppStep";
import { CALL_END_BY } from "../../enums/callEndBy";
import TimerComponent from "./Timer-component";
import AdvertisingCard from "./AdvertisingCard-component";
import { DEVICES } from "../../enums/devices";

export enum EXIT_CAUSED {
  BY_LOCAL_USER = "You left the meeting",
  BY_REMOTE_USER = "The other participant left the meeting",
  BY_ERROR = "Something went wrong. Please reconnect",
}

export const EndCall = () => {
  const navigate = useNavigate();
  const callRoomNumber = useSelector(selectCallNumber);
  const timerId = useRef(null);
  const [callTimer, setCallTimer] = useState(60);
  const dispatch = useDispatch();
  const mediaStreamRef = useRef(null);
  const callEndedBy = useSelector(selectCallEndBy);

  useEffect(() => {
    stopMediaTracks();
    startTimer();

    navigator.mediaDevices
      .getUserMedia({ video: true, audio: true })
      .then((stream) => {
        mediaStreamRef.current = stream;
      })
      .catch((error) => {
        console.error("Error accessing media devices.", error);
      });

    return () => {
      clearInterval(timerId.current);
      stopMediaTracks();
    };
  }, []);

  const startTimer = () => {
    timerId.current = setInterval(() => {
      setCallTimer((prevTimer) => prevTimer - 1);
    }, 1000);
  };

  useEffect(() => {
    if (callTimer === 0) {
      clearInterval(timerId.current);
      handleReturnHome();
    }
  }, [callTimer]);

  const stopMediaTracks = () => {
    navigator.mediaDevices.enumerateDevices().then((devices) => {
      devices.forEach((device) => {
        if (
          device.kind === DEVICES.CAMERA || device.kind === DEVICES.MICROPHONE
        ) {
          navigator.mediaDevices
            .getUserMedia({
              video: device.kind === DEVICES.CAMERA,
              audio: device.kind === DEVICES.MICROPHONE,
            })
            .then((stream) => {
              stream.getTracks().forEach((track) => track.stop());
            })
            .catch((error) => {
              console.error("Error stopping media devices.", error);
            });
        }
      });
    });

    if (mediaStreamRef.current) {
      mediaStreamRef.current.getTracks().forEach((track) => track.stop());
    }
  };

  // const handleRejoin = () => {
  //   clearInterval(timerId.current);
  //   timerId.current = null;
  //   dispatch(setUserStage(UserAppStep.ON_CALL));
  //   window.sessionStorage.setItem("userStage", UserAppStep.ON_CALL);
  //   navigate(`/meet/${callRoomNumber}`);
  // };

  const handleReturnHome = () => {
    clearInterval(timerId.current);
    timerId.current = null;
    navigate("/");

    window.sessionStorage.removeItem("userStage");
    window.sessionStorage.removeItem("callNumber");
    window.sessionStorage.removeItem("reloadState");
  };

  const handleSendFeedback = () => {
    clearInterval(timerId.current);
    timerId.current = null;
    navigate("/feedback");
  };

  const endCallText = CALL_END_BY.BY_REMOTE === callEndedBy
    ? EXIT_CAUSED.BY_REMOTE_USER
    : CALL_END_BY.BY_LOCAL === callEndedBy
    ? EXIT_CAUSED.BY_LOCAL_USER
    : EXIT_CAUSED.BY_ERROR;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "700px",
        position: "relative",
      }}
    >

      <Box
        sx={{
          position: "absolute",
          top: "0",
          left: "0",
          display: "flex",
          alignItems: "center",
          gap: 2,
        }}
      >
        <TimerComponent />
      </Box>

      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: 4,
          overflow: "auto",
          position: "relative",
          zIndex: 1,
        }}
      >
        <Typography variant="h3" textAlign="center">
          {endCallText}
        </Typography>

        <Box display="flex" gap={2}>
          {/* <UnifiedButton onClick={handleRejoin} text="Rejoin" /> */}
          <UnifiedButton
            onClick={handleReturnHome}
            text="Return to home screen"
          />
        </Box>

        <Typography
          onClick={handleSendFeedback}
          sx={{
            cursor: "pointer",
            color: COLOR.PURPLE,
            fontWeight: 700,
            ":hover": {
              color: "blueviolet",
            },
          }}
        >
          Submit Feedback
        </Typography>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
          }}
        >
          <AdvertisingCard />
        </Box>
      </Box>
    </Box>
  );
};
