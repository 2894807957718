import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectCallNumber } from '../redux/callSlice';

interface ProtectedRouteProps {
  element: React.ReactElement;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ element, ...rest }) => {
  const callNumber = useSelector(selectCallNumber);

  if (!callNumber) {
    return <Navigate to="/" />;
  }

  return element;
};

export default ProtectedRoute;
