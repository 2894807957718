import React from "react";
import { Box } from "@mui/material";
import { VideocamOff } from "@mui/icons-material";
import MicOffIcon from "@mui/icons-material/MicOff";
import { MediaState } from "./CallRoom";

interface RemoteVideoComponentProps {
  remoteVideoRef: React.MutableRefObject<HTMLVideoElement | null>;
  mediaState: MediaState;
}

const RemoteVideoComponent: React.FC<RemoteVideoComponentProps> = ({
  remoteVideoRef,
  mediaState,
}) => {
  const { VideoState, AudioState } = mediaState;

  return (
    <Box
      position="absolute"
      top="0"
      left="0"
      width="100%"
      height="100%"
      sx={{
        backgroundColor: VideoState ? "transparent" : "black",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden",
      }}
    >
      <video
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          zIndex: VideoState ? 0 : -1,
        }}
        ref={remoteVideoRef}
        autoPlay
        playsInline
      />
      {(!VideoState || !AudioState) && (
        <Box
          position="absolute"
          top="0"
          left="0"
          width="100%"
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          zIndex="10"
          sx={{ backgroundColor: !VideoState ? "black" : "transparent" }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 1,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {!VideoState && (
              <VideocamOff sx={{ fontSize: "4rem", color: "red" }} />
            )}
            {!AudioState && (
              <MicOffIcon sx={{ fontSize: "4rem", color: "red" }} />
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default RemoteVideoComponent;
