import { Box } from "@mui/material";

import { Feedback } from "../components/Feedback-component/Feedback";

export const FeedbackPage = () => {
  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <Feedback />
    </Box>
  );
};
