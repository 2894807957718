import React, { useEffect, useState } from "react";
import {
  Box,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
  Link,
  Tooltip,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCallControllsOnRight,
  selectCallNumber,
  setCallSettings,
} from "../../redux/callSlice";
import EditIcon from "@mui/icons-material/Edit";
import { COLOR } from "../../enums/styles";
import { selectUserStage, setUserClearSetup, setUserStage } from "../../redux/userSlice";
import { UserAppStep } from "../../enums/userAppStep";
import { useDeviceOrientation } from "../../helpers/useDeviceOrientation";
import { useNavigate } from "react-router-dom";

export const Footer = ({ cleanUp }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [isHidden, setIsHidden] = useState(false);
  const [isPolicyVisible, setIsPolicyVisible] = useState(false);

  const { t } = useTranslation();
  const callNumber = useSelector(selectCallNumber);
  const userStage = useSelector(selectUserStage);
  const isCallControllsOnRight = useSelector(selectCallControllsOnRight);

  const isPortrait = useDeviceOrientation()
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));


  useEffect(() => {
    setIsPolicyVisible(
      ![UserAppStep.ON_CALL, UserAppStep.RECONNECTING].includes(userStage)
    );

    
  }, [userStage]);

  useEffect(() => {
    setIsHidden(
      [UserAppStep.ON_CALL, UserAppStep.END_CALL, UserAppStep.RECONNECTING, UserAppStep.LOGIN].includes(userStage)
    );
  }, [userStage]);


  const handleCallExit = () => {
    cleanUp()
    dispatch(setUserStage(UserAppStep.LOGIN))
    dispatch(setCallSettings({
      camera: false,
      microphone: false,
      speaker: false,
  }))
  dispatch(setUserClearSetup())

  navigate("/")
  }


  if (isCallControllsOnRight) {
    return (
      <Box
        position="fixed"
        height="100%"
        right="0"
        top="0"
        zIndex="10"
        width="75px"
        sx={{ backgroundColor: COLOR.PURPLE, color: "white" }}
      />
    );
  }

  return (
    <Box
      position={isPortrait ? "fixed" : "static"}
      bottom="0"
      height="75px"
      left="0"
      zIndex="10"
      width="100%"
      sx={{ backgroundColor: COLOR.PURPLE, color: "white" }}
    >
      <Box p={2} display="flex" justifyContent="space-between" height="100%">
        {!isHidden && (
          <Box
            visibility={callNumber ? "visible" : "hidden"}
            display="flex"
            gap="20px"
            alignItems="center"
            justifyContent="center"
          >
            <Box
              display="flex"
              flexDirection="row"
              gap={isMobile ? "5px" : "20px"}
              justifyContent="center"
              alignItems="center"
            >
              <Typography sx={{ color: "white", fontSize: isMobile ? "16px" : "25px", fontWeight: "700" }}>
                PIN:
              </Typography>
              <Typography
                sx={{
                  letterSpacing: isMobile ? 1 : 3,
                  fontWeight: "700",
                  fontSize: isMobile ? "16px" : "25px",
                  color: "white",
                }}
              >
                {callNumber}
              </Typography>
            </Box>
            <Tooltip title="Change PIN" arrow>
              <span>
                <IconButton
                  disabled={userStage === UserAppStep.LOGIN}
                  onClick={handleCallExit}
                  size="large"
                  sx={{
                    color: "white",
                    "&:hover": {
                      color: COLOR.PURPLE,
                      backgroundColor: "white",
                    },
                  }}
                >
                  <EditIcon />
                </IconButton>
              </span>
            </Tooltip>
          </Box>
        )}
        {isPolicyVisible && (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "right",
              gap: isMobile ? "5px" : "20px",
            }}
          >
            <Link
              href="https://www.google.com.ua/?hl=uk"
              target="_blank"
              rel="noreferrer"
              sx={{
                textWrap: "wrap",
                fontSize: isMobile ? "12px" : null,
                textDecoration: "none",
                color: "white",
                fontWeight: "700",
                ":hover": {
                  color: COLOR.VIOLET,
                },
              }}
            >
              {t("policy")}
            </Link>
            <Link
              href="https://www.google.com.ua/?hl=uk"
              target="_blank"
              rel="noreferrer"
              sx={{
                textDecoration: "none",
                fontSize: isMobile ? "12px" : null,
                color: "white",
                fontWeight: "700",
                ":hover": {
                  color: COLOR.VIOLET,
                },
              }}
            >
              {t("imprint")}
            </Link>
          </Box>
        )}
      </Box>
    </Box>
  );
};
