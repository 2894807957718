import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  selectUserSettings,
  setUserCameraInfo,
  setUserMicrophoneInfo,
  setUserSpeakerInfo,
  setUserStage,
} from "../../redux/userSlice";
import { useNavigate } from "react-router-dom";
import { selectCallNumber, setCallSettings } from "../../redux/callSlice";
import { UserAppStep } from "../../enums/userAppStep";
import { DEVICES } from "../../enums/devices";
import { DeviceSettings } from "./SetupCall-DeviceSettings";
import { UnifiedButton } from "../UnifiedButton-component/UnifiedButton";
import { useWindowWidth } from "../../helpers/useWindowWidth";
import { VideocamOff } from "@mui/icons-material";
import { useDeviceOrientation } from "../../helpers/useDeviceOrientation";
import * as awrtc from "../../awrtc";

interface SetupCallProps {
  localStreamRef: MutableRefObject<MediaStream | null>;
}

export const SetupCall: React.FC<SetupCallProps> = ({ localStreamRef }) => {
  const windowWidth = useWindowWidth();
  const room = useSelector(selectCallNumber);
  const userSettings = useSelector(selectUserSettings);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const videoRef = useRef<HTMLVideoElement>(null);
  const [cameraEnabled, setCameraEnabled] = useState<boolean>(true);
  const [microphoneEnabled, setMicrophoneEnabled] = useState<boolean>(true);
  const [speakerEnabled, setSpeakerEnabled] = useState<boolean>(true);
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isPortrait = useDeviceOrientation();
  const selectedVideoDevice = userSettings.userCameraInfo.deviceId;
  const selectedMicDevice = userSettings.userMicrophoneInfo.deviceId;

  useEffect(() => {
    const getUserMedia = async () => {
      try {
        const constraints = {
          video: selectedVideoDevice
            ? { deviceId: { exact: selectedVideoDevice } }
            : true,
          audio: selectedMicDevice
            ? { deviceId: { exact: selectedMicDevice } }
            : true,
        };

        if (localStreamRef.current) {
          localStreamRef.current.getTracks().forEach((track) => track.stop());
          localStreamRef.current = null;
        }

        const userStream = await awrtc.DeviceApi.getAssetUserMedia(constraints);
        localStreamRef.current = userStream;

        if (videoRef.current) {
          videoRef.current.srcObject = localStreamRef.current;
          videoRef.current.muted = true;

          // Ensure the video element is ready before calling play()
          videoRef.current.onloadedmetadata = async () => {
            try {
              await videoRef.current.play();
            } catch (error) {
              console.error("Error playing video:", error);
            }
          };
        } else {
          console.error("Video reference is not set.");
        }
      } catch (error) {
        if (error.name === "OverconstrainedError") {
          console.error(
            "OverconstrainedError: The constraints specified cannot be met.",
            error
          );
        } else {
          console.error("Error accessing media devices.", error);
        }
      }
    };

    getUserMedia();
  }, [selectedVideoDevice, selectedMicDevice, localStreamRef]);

  // Handles device enumeration
  useEffect(() => {
    const getDevices = async () => {
      try {
        const devices = await navigator.mediaDevices
          .enumerateDevices()
          .then((res) => {
            return res.filter(
              (device) =>
                device.deviceId !== "default" &&
                device.deviceId !== "communications"
            );
          });
        setDevices(devices);

        // Set default selected devices
        const defaultCamera = devices.find(
          (device) => device.kind === DEVICES.CAMERA
        );
        const defaultMicrophone = devices.find(
          (device) => device.kind === DEVICES.MICROPHONE
        );
        const defaultSpeaker = devices.find(
          (device) => device.kind === DEVICES.SPEAKER
        );
        if (defaultCamera) {
          dispatch(
            setUserCameraInfo({
              deviceId: defaultCamera.deviceId,
              label: defaultCamera.label,
            })
          );
        }
        if (defaultMicrophone) {
          dispatch(
            setUserMicrophoneInfo({
              deviceId: defaultMicrophone.deviceId,
              label: defaultMicrophone.label,
            })
          );
        }
        if (defaultSpeaker) {
          dispatch(
            setUserSpeakerInfo({
              deviceId: defaultSpeaker.deviceId,
              label: defaultSpeaker.label,
            })
          );
        }
      } catch (error) {
        console.error("Error enumerating devices:", error);
      }
    };

    getDevices();
  }, []);

  // Updates stream tracks and video volume based on device state
  useEffect(() => {
    if (localStreamRef.current) {
      console.log("Updating stream tracks...");
      localStreamRef.current
        .getVideoTracks()
        .forEach((track) => (track.enabled = cameraEnabled));
      localStreamRef.current
        .getAudioTracks()
        .forEach((track) => (track.enabled = microphoneEnabled));
    }
    if (videoRef.current) {
      videoRef.current.volume = speakerEnabled ? 1 : 0;
    }
  }, [cameraEnabled, microphoneEnabled, speakerEnabled, localStreamRef]);

  // Handles join call button click
  const handleJoinCall = () => {
    console.log("Joining call with settings:", {
      camera: cameraEnabled,
      microphone: microphoneEnabled,
      speaker: speakerEnabled,
    });
    dispatch(
      setCallSettings({
        camera: cameraEnabled,
        microphone: microphoneEnabled,
        speaker: speakerEnabled,
      })
    );
    dispatch(setUserStage(UserAppStep.ON_CALL));
    window.sessionStorage.removeItem("reloadState");
    window.sessionStorage.setItem("userStage", UserAppStep.ON_CALL);
    navigate(`/meet/${room}`);
  };

  const handleChangeCamera = (selectedDeviceId: string) => {
    const currentCamera = devices.find(
      (item) =>
        item.deviceId === selectedDeviceId && item.kind === DEVICES.CAMERA
    );
    dispatch(
      setUserCameraInfo({
        deviceId: currentCamera.deviceId,
        label: currentCamera.label,
      })
    );
  };

  const handleChangeMic = (selectedDeviceId: string) => {
    const currentMic = devices.find(
      (item) =>
        item.deviceId === selectedDeviceId && item.kind === DEVICES.MICROPHONE
    );

    dispatch(
      setUserMicrophoneInfo({
        deviceId: currentMic.deviceId,
        label: currentMic.label,
      })
    );
  };

  const handleChangeSpeaker = (selectedDeviceId: string) => {
    const currentSpeaker = devices.find(
      (item) =>
        item.deviceId === selectedDeviceId && item.kind === DEVICES.SPEAKER
    );

    dispatch(
      setUserSpeakerInfo({
        deviceId: currentSpeaker.deviceId,
        label: currentSpeaker.label,
      })
    );
  };

  // Toggles device state
  const toggleDeviceCheck = (device: string) => {
    if (device === DEVICES.CAMERA) {
      setCameraEnabled(!cameraEnabled);
    } else if (device === DEVICES.MICROPHONE) {
      setMicrophoneEnabled(!microphoneEnabled);
    } else if (device === DEVICES.SPEAKER) {
      setSpeakerEnabled(!speakerEnabled);
      if (videoRef.current) {
        videoRef.current.volume = speakerEnabled ? 1 : 0;
      }
    }
  };
  const portraitMode = !isPortrait && isMobile ? 450 : 300;

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="space-between"
      height="100%"
      gap="20px"
    >
      <Box
        display="flex"
        flexDirection={isMobile ? "column" : "row"}
        justifyContent="center"
        alignItems="center"
        width="100%"
        height="100%"
        gap="10px"
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          boxSizing="border-box"
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            p={0}
            m={0}
          >
            <Box
              sx={{
                position: "relative",
                boxSizing: "border-box",
                display: "flex",
                justifyContent: "center",
                p: 0,
                m: 0,
                alignItems: "center",
              }}
            >
              <video
                style={{
                  height: "auto",
                  borderRadius: "5px",
                  width: isMobile
                    ? `${portraitMode}px`
                    : windowWidth > 1100
                    ? "540px"
                    : "400px",
                }}
                autoPlay
                playsInline
                muted={!speakerEnabled}
                ref={videoRef}
              />
              {!cameraEnabled && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    color: "white",
                  }}
                >
                  <VideocamOff style={{ fontSize: "3rem" }} />
                </Box>
              )}
            </Box>
          </Box>
        </Box>
        <DeviceSettings
          handleCameraChange={handleChangeCamera}
          handleMicrophoneChange={handleChangeMic}
          handleSpeakerChange={handleChangeSpeaker}
          userSettings={userSettings}
          speakerEnabled={speakerEnabled}
          cameraEnabled={cameraEnabled}
          microphoneEnabled={microphoneEnabled}
          isMobile={isMobile}
          devices={devices}
          toggleDeviceCheck={toggleDeviceCheck}
          isPortrait={isPortrait}
        />
      </Box>
      <UnifiedButton text="join_call" onClick={handleJoinCall} />
    </Box>
  );
};
