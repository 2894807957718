import * as awrtc from "../awrtc";

export const handleMediaUpdate = (
  args: awrtc.MediaUpdatedEventArgs,
  localVideoRef: React.RefObject<HTMLVideoElement>,
  remoteVideoRef: React.RefObject<HTMLVideoElement>,
  remoteStreamRef: React.MutableRefObject<MediaStream | null>,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const videoElement = args.VideoElement;
  const { ConnectionId } = args;
  console.log("Media Update Event:", args);
  console.log("Connection ID:", ConnectionId.id);
  console.log("Video Element:", videoElement);

  if (ConnectionId.id === -1) {
    // Handle local video update
    if (localVideoRef.current) {
      localVideoRef.current.srcObject = videoElement.srcObject;
    }
  } else {
    // Handle remote video update
    console.log(
      "Remote stream update for connection:",
      ConnectionId.id,
      videoElement.srcObject
    );

    setLoading(false);
    if (remoteVideoRef.current) {
      // Ensure the `srcObject` is of type `MediaStream`
      const stream = videoElement.srcObject as MediaStream;
      if (stream) {
        remoteStreamRef.current = stream; // Correctly assigning stream to ref
        remoteVideoRef.current.srcObject = stream;
        console.log(
          "remoteVideoRef.current.srcObject:",
          remoteVideoRef.current.srcObject
        );
      }
    }
  }
};

export const formatTime = (totalSeconds) => {
  const minutes = Math.floor(totalSeconds / 60);
  const secs = totalSeconds % 60;
  return `${String(minutes).padStart(2, "0")}:${String(secs).padStart(2, "0")}`;
};

export const cleanUp = async (localStreamRef) => {

  const interval_id = window.setInterval(function () {},
  Number.MAX_SAFE_INTEGER);

  // Clear any timeout/interval up to that id
  for (let i = 1; i < interval_id; i++) {
    window.clearInterval(i);
  }
  // Select the video element
  const video = document.querySelector("video") as HTMLVideoElement | null;

  if (video && video.srcObject instanceof MediaStream) {
    const mediaStream = video.srcObject as MediaStream;

    // Stop and remove all tracks from the MediaStream
    mediaStream.getTracks().forEach((track) => {
      mediaStream.removeTrack(track);
      track.stop();
    });

    // Check if any tracks remain
    const remainingTracks = mediaStream.getTracks();
    if (remainingTracks.length > 0) {
      console.error("Some tracks are still active after cleanup.");
    } else {
      console.log("All tracks have been successfully stopped and removed.");
    }
  } else {
    console.warn("No valid MediaStream found on video element.");
  }

  if (localStreamRef.current) {
    localStreamRef.current.getTracks().forEach((track) => {
      track.stop();
      track.enabled = false;
    });

    // Check if any tracks remain in localStreamRef
    const remainingLocalTracks = localStreamRef.current.getTracks();
    if (remainingLocalTracks.length > 0) {
      console.error("Some local tracks are still active after cleanup.");
    } else {
      console.log("All local tracks have been successfully stopped.");
    }

    // Clear the localStreamRef
    localStreamRef.current = null;
  } else {
    console.warn("No local stream reference found.");
  }

  // Clear session storage
  window.sessionStorage.removeItem("callNumber");
  window.sessionStorage.removeItem("userStage");

  // Additional check to confirm session storage is cleared
  const callNumber = window.sessionStorage.getItem("callNumber");
  const userStage = window.sessionStorage.getItem("userStage");
  if (callNumber === null && userStage === null) {
    console.log("Session storage cleared successfully.");
  } else {
    console.error("Session storage clearance failed.");
  }
};