import React, { useEffect } from "react";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import { selectUserStage } from "../redux/userSlice";
import { UserAppStep } from "../enums/userAppStep";
import { useNavigate } from "react-router-dom";
import { Permission } from "../components/Permission-component/Permission";

export const ErrorPage = () => {
  const userStage = useSelector(selectUserStage);
  const navigate = useNavigate();
  
  useEffect(() => {
    if (userStage !== UserAppStep.PERMISSION) {
      navigate("/");
    }
  }, [userStage, navigate]);

  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <Permission />
    </Box>
  );
};
