import React from "react";
import { Rating } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";

export const StarRating = ({ name, value, onChange }) => {
  const iconSize = 80;

  return (
    <Rating
      name={name}
      value={value}
      onChange={(event, newValue) => onChange(newValue)}
      icon={<StarIcon style={{ fontSize: iconSize }} />}
      emptyIcon={<StarBorderIcon style={{ fontSize: iconSize }} />}
      size="large"
    />
  );
};

