import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { selectUserStage } from "../../redux/userSlice";
import { UserAppStep } from "../../enums/userAppStep";
import { Loader } from "../Loader-component/Loader";
import { Link } from "react-router-dom";
import { selectCallError } from "../../redux/callSlice";
import { useTranslation } from "react-i18next";

export const Reconnect = ({ handleEndCall, isCallable }) => {
  const { t } = useTranslation()
  const callError = useSelector(selectCallError)
  const userStage = useSelector(selectUserStage);
  const navigate = useNavigate();

  useEffect(() => {
    if (
      userStage !== UserAppStep.RECONNECTING &&
      userStage !== UserAppStep.END_CALL
    ) {
      navigate("/");
    }
  }, [userStage, navigate]);

  return (
    <Box
      position="relative"
      zIndex="20"
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      gap={3}
    >
      <Box>
        <Loader />
      </Box>
      <Typography textAlign="center" variant="h6">
       {callError}
      </Typography>
      <Typography>
        <Typography component="span">
          <Link
            style={{
              textDecoration: "none",
              fontWeight: "600",
              fontSize: "18px",
            }}
            onClick={handleEndCall}
            to={isCallable ? "/checkout" : "/"}
          >
            {t("change_pin")}
          </Link>
        </Typography>
      </Typography>
    </Box>
  );
};
