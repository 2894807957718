import { Box } from "@mui/material";
import React, { useEffect } from "react";
import { SetupCall } from "../components/SetupCall-component/SetupCall";
import { useDispatch, useSelector } from "react-redux";
import { selectUserStage, setUserStage } from "../redux/userSlice";
import { UserAppStep } from "../enums/userAppStep";
import { useNavigate } from "react-router-dom";
import { selectCallNumber } from "../redux/callSlice";

export const SetupCallPage = ({ localStreamRef }) => {
  const callNumber = useSelector(selectCallNumber);
  const userStage = useSelector(selectUserStage);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setUserStage(UserAppStep.PREPARING));

    if (!callNumber) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    if (userStage === UserAppStep.PREPARING) {
      window.sessionStorage.setItem("userStage", userStage);
    }
  }, [userStage]);

  return (
    <Box display="flex" alignItems="center" justifyContent="center" minHeight="600px">
      <SetupCall localStreamRef={localStreamRef} />
    </Box>
  );
};
