import { Box } from "@mui/material";
import React from "react";
import { EndCall } from "../components/EndCall-component/EndCall";

export const EndCallPage = () => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
      }}
    >
      <EndCall />
    </Box>
  );
};
